// breakpoints
@mixin breakpoint($class) {
  @if $class==xs {
    @media (max-width: 767px) {
      @content;
    }
  } @else if $class==sm {
    @media (min-width: 768px) {
      @content;
    }
  } @else if $class==md {
    @media (min-width: 992px) {
      @content;
    }
  } @else if $class==lg {
    @media (min-width: 1200px) {
      @content;
    }
  } @else {
    @warn "Breakpoint mixin supports: xs, sm, md, lg";
  }
}

// Color Map
$colors: (
  "blue": (
    "xxxxlight": #ebf8ff,
    "xxxlight": #3f78a6,
    //0072cf //00a8ff
      "xxlight": #008dd6,
    "xlight": #0072cf,
    "base": #1c57a5,
    "darker": #002b45,
    "funBlue": #1c57a5
  ),
  "yellow": (
    "xxlight": #ffd8a0,
    "xlight": #d1c99d,
    "base": #fda01a,
    "xdark": #fde500,
    "xxdark": #f9cd00,
    "xxxdark": #725f08
  ),
  "purple": (
    "lighter": #afb8ea,
    "light": #7e7c80,
    "mpp": #5648ad,
    "mppw": #604776
  ),
  "gray": (
    "xxxlight": #c9cac8,
    "xxlight": #f8f8f8,
    "xlight": #d7d9d6,
    "base": #4D4D4D,
    "xdark": #868686,
    "xxdark": #757575,
    "xxxdark": #333333,
    "darkGray": #7f7f7f
  ),
  "red": (
    "xlight": #DC3545,
    "base": #d11241,
    "xdark": #9c033a,
    "xxdark": #9d033e,
    "xxxdark": #6d0228
  ),
  "white": (
    "base": #ffffff
  ),
  "black": (
    "base": #000000
  ),
  "green": (
    // "base": #1c8a37,
    "base": #356240
  )
);

// Color Function
@function nw-color($color, $shade: "base", $map: $colors) {
  // check color exists
  @if (map-has-key($map, $color)) {
    $value: map-get($map, unquote($color));
    // check if color or map
    @if type-of($value) == color {
      // return color
      @return $value;
    }
    // check shade of color exists
    @if (map-has-key($value, $shade)) {
      // return shade of color
      @return map-get($value, $shade);
    }
  }
  // else do nothing
  @return null;
}

// example
/*
.color {
  color: nw-color('blue', darker);
  background-color: nw-color('red');
}
*/
